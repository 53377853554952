<template>
  <div>
    <div style="margin-bottom: 15px">
      <van-search
          v-model="searchParamValue"
          show-action
          label="商户名"
          placeholder="请输入商户名关键词"
          @search="onSearch"
          @clear="onSearchClear"
      >
        <template #action>
          <div @click="showPopup" style="color: #1989fa">筛选</div>
        </template>
      </van-search>
    </div>

    <OrderList :orderId="1" :searchParam="searchParam" ref="OrderList"></OrderList>
    <van-popup position="bottom" :style="{ height: '90%' }" v-model="orderInfoPopupShow">
      <van-field v-model="searchParam.username" label="商户名" placeholder="请输入商户名"/>
      <van-field v-model="searchParam.storeName" label="商品名" placeholder="请输入商品名"/>
      <van-field v-model="searchParam.sku" label="规格" placeholder="请输入规格"/>
      <van-field name="radio" label="排序">
        <template #input>
          <van-radio-group v-model="searchParam.orderBy" direction="horizontal">
            <van-radio name="orderTime">订单时间</van-radio>
            <van-radio name="sales">销量</van-radio>
            <van-radio name="amount">销售额</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
          v-model="searchParam.address"
          is-link
          readonly
          label="地区"
          placeholder="请选择所在地区"
          @click="show = true"
      />
      <van-field v-model="date" label="订单日期" placeholder="选择订单日期" @click="showCalendar = true"/>
      <van-calendar v-model="showCalendar" :show-confirm="false" :min-date="minDate" :max-date="maxDate" @confirm="onConfirm"/>

      <div style="text-align: center;">
        <van-button style="margin-top: 20px; width: 80%" size="large" type="primary" @click="search">搜索</van-button>
      </div>

      <van-popup v-model="show" round position="bottom">
        <van-cascader
            v-model="cascaderValue"
            title="请选择所在地区"
            :options="options"
            :field-names="fieldNames"
            @close="show = false"
            @finish="onFinish"
            @change="onChange"
        />
      </van-popup>
    </van-popup>

  </div>
</template>

<script>
import {
  Button,
  Calendar, Cascader, Cell, Field, Popup, Radio, RadioGroup, Search
} from 'vant';
import * as getDataService from "@/api/serverData/getServerData";
import OrderList from "@/view/order/list";

export default {

  components: {
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Cascader.name]: Cascader,
    [Calendar.name]: Calendar,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Search.name]: Search,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    OrderList
  },

  data() {
    return {
      searchParamValue: '',
      searchParam: {
        storeName: '',
        sku: '',
        orderBy: 'orderTime'
      },
      orderInfoPopupShow: false,
      text: '',
      date: '',
      showCalendar: false,
      show: false,
      cascaderValue: '',
      // 选项列表，children 代表子选项，支持多级嵌套
      options: [
        {
          text: '浙江省',
          value: '330000',
          children: [{text: '杭州市', value: '330100'}],
        },
        {
          text: '江苏省',
          value: '320000',
          children: [{text: '南京市', value: '320100'}],
        },
      ],
      fieldNames: {
        text: 'n',
        value: 'v',
        children: 'c',
      },
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(2030, 0, 31),
    };
  },
  created() {
    getDataService.getCity().then(res => {
      if (res.status === 200) {
        this.options = res.data
      }
    });
  },
  methods: {
    onSearchClear() {
      this.searchParam.username = null
      this.searchParam.address = null
      this.searchParam.orderDatetime = null
      this.searchParam.storeName = ''
      this.searchParam.sku = ''
      this.searchParam.orderBy = 'orderTime'
      this.$refs.OrderList.onRefresh();
    },
    onSearch() {
      this.searchParam.username = this.searchParamValue
      this.$refs.OrderList.onRefresh();
    },
    search() {
      this.searchParamValue = '多条件筛选'
      this.$refs.OrderList.onRefresh();
      this.orderInfoPopupShow = false
    },
    showPopup() {
      this.orderInfoPopupShow = true
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm(date) {
      this.showCalendar = false;
      this.date = this.formatDate(date);
      this.searchParam.orderDatetime = this.formatDate(date);
    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({selectedOptions}) {
      console.log(selectedOptions)
      this.show = false;
      this.address = selectedOptions.map((option) => option.n).join(' ');
      this.searchParam.address = selectedOptions.map((option) => option.n).join(' ');
    },
    onChange({selectedOptions}) {
      console.log(selectedOptions)
      this.address = selectedOptions.map((option) => option.n).join(' ');
      this.searchParam.address = selectedOptions.map((option) => option.n).join(' ');
    }
  }
};
</script>

<style lang="less">
.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
</style>
